import './contact.css';

import { contactLogo, facebookLogo, instaLogo, linkedInLogo, vimeoLogo } from '../../images/images';

import { Card } from 'react-bootstrap';
import database from "../../data/database.json";
import dt from "../../data/database.json";
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const contact = database.contact

const AllLinks = () => {
    const vimeoSize = 28;
    const size = 28;
    const instagram = <div>
        <a href={contact.instagram} target="_blank" rel="noreferrer">
            <LazyLoadImage src={instaLogo} alt='instagram' width={size} height={size} />
        </a>
    </div>

    const facebook = <div>
        <a href={contact.facebook} target="_blank" rel="noreferrer">
            <LazyLoadImage src={facebookLogo} alt='instagram' width={size} height={size} />
        </a>
    </div>

    const mail = <div>
        <a href={`mailto:${contact.email}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
        </a>
    </div>
    const vimeo = <div>
        <a href={contact.vimeo} target="_blank" rel="noreferrer">
            <LazyLoadImage src={vimeoLogo} alt='vimeo' width={vimeoSize} height={vimeoSize} />
        </a>
    </div>
    const linkedIn = <div>
        <a href={contact.linkedIn} target="_blank" rel="noreferrer">
            <LazyLoadImage src={linkedInLogo} alt='linkedIn' width={size} height={size} />
        </a>
    </div>;
    return <div className="contact" id="contact">
        <div className='contact-heading'>
            <div>CONTACT</div>
        </div>
        <div>
            <div className="row social-content">
                <div className="col social-content-col">
                    <Card.Body className='contact-body'>
                        <Card.Img src={contactLogo}></Card.Img>
                        <Card.Subtitle>MUMBAI, INDIA</Card.Subtitle>
                        <Card.Subtitle>FOR BUSINESS ENQUIRIES</Card.Subtitle>
                        <Card.Text className='email'>
                            {mail}
                            <div className='email-text'>{contact.email}</div>
                        </Card.Text>
                    </Card.Body>
                </div>
                <div className={`col social ${!isMobile ? "height-174" : ""}`}>
                    <div className="social-links">
                        {linkedIn}
                        {instagram}
                        {vimeo}
                        {facebook}
                    </div>
                </div>
            </div>
        </div>
        <footer className="copyright">
            &copy; Copyright {dt.copyright}
        </footer>
    </div>;
}
export default AllLinks;