import React, { Component, ReactNode } from 'react';

interface LazySectionProps {
  children: ReactNode;
  threshold?: number;
}

interface LazySectionState {
  isVisible: boolean;
}

class LazySection extends Component<LazySectionProps, LazySectionState> {
  private observer: IntersectionObserver | null = null;
  private sectionRef: React.RefObject<HTMLDivElement>;

  constructor(props: LazySectionProps) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.sectionRef = React.createRef();
  }

  componentDidMount() {
    const { threshold = 0.5 } = this.props;

    // Create an Intersection Observer to track visibility of the section
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ isVisible: true });
        }
      },
      {
        threshold,
      }
    );

    const element = this.sectionRef.current;
    if (element && this.observer) {
      this.observer.observe(element);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect(); // Clean up the observer on unmount
    }
  }

  render() {
    const { isVisible } = this.state;
    const { children } = this.props;

    return (
      <div ref={this.sectionRef} style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 1s ease-in-out' }}>
        {isVisible ? children : null}
      </div>
    );
  }
}

export default LazySection;
