import './work.css';

import * as allImages from "../../images/images";

import Dialog from '../helper/dialog';
import database from "../../data/database.json";
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from 'react';

export interface iWork {
    image: any,
    caption: string,
    description: string,
    link?: string
}

const commercialData: iWork[] = database.commercials;

export const Commercials = () => {
    const [show, setShow] = useState(false);
    const [link, setLink] = useState("");
    const handleClose = () => {
        setShow(false)
    }
    const allImgs = Object.entries(allImages);
    return <div id="commercials" className="commercials">
        <div className="commercials-heading">
            <div>COMMERCIALS</div>
        </div>
        <div className="commercial-section">
            <div>
                <div className="row">
                    {commercialData.map((entity, index) => {
                        const entityImage = allImgs.find((x) => x[0] === entity.image);
                        return <div className={`${isMobile === true ? "col-12" : "col-4"}`} key={`comercialItem${index}`}>
                            <div className="card card-onhover" onClick={() => {
                                setShow(true);
                                if (entity.link) {
                                    setLink(entity.link);
                                }
                            }}>
                                <LazyLoadImage src={entityImage?.[1]} className="card-img-top" alt={entity.caption} />
                                <div className="card-body">
                                    <h5 className=" card-title card-title-commercial">{entity.description}</h5>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <Dialog show={show} handleClose={handleClose} link={link} />
    </div>;
}