import About from "../components/about/about";
import AllLinks from "../components/contact/allLinks";
import { Commercials } from "../components/work/commercial";
import MainPage from "../components/mainPage";
import React from "react";
import { Shorts } from "../components/work/shorts";
import Video from "../components/videos/videos";
import LazySection from "./lazySection";

function DashBoard() {
    return (
        <div className="dashboard">
            <LazySection>
                <MainPage key="section1"/>
            </LazySection>
            <LazySection>
                <Video key="section2"/>
            </LazySection>
            <LazySection>
                <Commercials key="section3"/>
            </LazySection>
            <LazySection>
                <Shorts key="section4"/>
            </LazySection>
            <LazySection>
                <About key="section5"/>
            </LazySection>
            <AllLinks />
        </div>
    );
}

export default DashBoard;