import './header.css';

import { bkLogoMark } from '../../images/images';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Header = () => {
    const [open, setOpen] = useState(false);
    return <>
        <div className="main-header" id="header">
            <div className='main-header-container'>
                <a href='#header'>
                    <div className="logo-bklogomark">
                        <LazyLoadImage src={bkLogoMark} alt="bklogomark" className="rounded-circle" />
                    </div>
                </a>
                <div className='menu-section'>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="/navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {
                            setOpen(!open);
                        }}>
                            {!open ?
                                <svg viewBox="0 0 24 24" width="24" height="24" style={{ stroke: "white", strokeWidth: "2" }}>
                                    <line x1="4" y1="6" x2="20" y2="6" color={"#282828"} />
                                    <line x1="4" y1="12" x2="20" y2="12" />
                                    <line x1="4" y1="18" x2="20" y2="18" color={"#282828"} />
                                </svg> :

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={"white"} className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>}
                        </button>
                        <div className={`${open ? "" : "collapse"} navbar-collapse`} id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#kaamkaaj" onClick={() => { open && setOpen(false) }}>KAAMKAAJ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#about" onClick={() => { open && setOpen(false) }}>ABOUT US</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#contact" onClick={() => { open && setOpen(false) }}>CONTACT</a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
        {/* {Menu(open)} */}
    </>
}