import { LazyLoadImage } from 'react-lazy-load-image-component';
import { collagePage3, j } from '../../images/images';

import { isMobile } from 'react-device-detect';

export const Work = () => {
    return <div className="work" >
        <div className='work-image-container'>
            <div className='work-links'>
                <LazyLoadImage src={`${!isMobile ? collagePage3 : j}`} alt='news' className='news' />
                <div className='sub-links'>
                    <div>
                        <a href='#commercials'>COMMERCIALS</a>
                    </div>
                    <div>
                        <a href='#shorts'>SHORTS</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}