import './App.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import "react-lazy-load-image-component/src/effects/blur.css";

import DashBoard from './module/dashboard';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DashBoard />} />
          <Route
            path="*"
            element={<Navigate to="/" replace={true} />}
          />
          {/* <Route path="/login" element={<Admin />} />
          <Route path="/dashboard" element={<AdminDashboard {...data} />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
