import './work.css';

import * as allImages from "../../images/images";

import Dialog from '../helper/dialog';
import database from "../../data/database.json";
import { iWork } from './commercial';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const shortsData: iWork[] = database.shorts;


export const Shorts = () => {
    const [show, setShow] = useState(false);
    const [link, setLink] = useState("");
    const handleClose = () => {
        setShow(false)
    }
    const allImgs = Object.entries(allImages);
    return <div className="shorts " id="shorts">
        <div className="shorts-heading">
            <div>SHORTS</div>
        </div>
        <div className='container'>
            <div className="row">
                {shortsData.map((entity,index) => {
                    const entityImage = allImgs.find((x) => x[0] === entity.image);
                    return <div className={`${isMobile ? "col-12" : "col-6"}`} key={`shortItem${index}`}>
                        <div className="card card-onhover" onClick={() => {
                            setShow(true);
                            if (entity.link) {
                                setLink(entity.link);
                            }
                        }}>
                            <LazyLoadImage src={entityImage?.[1]} className="card-img-top" alt={entity.caption} />
                            <div className="card-body">
                                <h5 className="card-title card-title-commercial">{entity.description}</h5>
                            </div>
                        </div>
                    </div>
                })}

            </div>
        </div>
        <Dialog show={show} handleClose={handleClose} link={link} />
    </div>;
}